import Vue from 'vue'

import {
	Button,
	Input,
	Carousel,
	CarouselItem,
	Pagination,
	MessageBox,
	Message,
	Select,
	TimeSelect,
	Option,
	Loading,
	Autocomplete,
	Dialog
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

const element = {
	install: function (Vue) {
		Vue.use(Button)
		Vue.use(Input)
		Vue.use(Carousel)
		Vue.use(CarouselItem)
		Vue.use(Pagination)
		Vue.use(Select)
		Vue.use(Option)
		Vue.use(Loading)
		Vue.use(Autocomplete)
		Vue.use(Dialog)

		Vue.use(TimeSelect)

		Vue.prototype.$msgbox = MessageBox
		Vue.prototype.$confirm = MessageBox.confirm
		Vue.prototype.$message = Message
		Vue.prototype.$prompt = MessageBox.prompt
		Vue.prototype.$message = Message
	}
}
Vue.use(element)
export default element
