import axios from 'axios'
import router from '../router' //跳转路由用
import store from '../store'
import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'

//import md5 from 'js-md5'
import NProgress from 'nprogress' //页面加载顶部进度条
import 'nprogress/nprogress.css'

const request = axios.create({
	baseURL: '',
	timeout: 50000,
	withCredentials: true // cookie跨域必备
})
// http request 拦截器 Request
request.interceptors.request.use(
	config => {
		config.headers = {
			'Content-Type': 'application/json',
			token: ''
		}
		return config
	},
	error => {
		Promise.reject(error)
	}
)
// http response 拦截器 Response
request.interceptors.response.use(
	response => {
		NProgress.done()
		// code == 0: 成功
		const res = response.data
		// if (res.errorCode == 401) {

		// }
		/*if(res.data.errorCode === 401) {
			router.replace('siginIn');
			window.localStorage.clear();
			return
		}*/
		return Promise.resolve(res)
	},
	error => {
		reject(error.response.data)
		NProgress.done()
	}
)

function getLocalToken() {
	const token = window.localStorage.getItem('Token')
	return token
}

function fetch(url, params, methods, contnetType) {
	NProgress.start()
	var config = {
		method: methods || 'POST',
		url: url
	}
	if (methods && (methods === 'post' || methods === 'POST')) {
		config.data = JSON.stringify(params) //JSON.stringify(params); //obj.toJSONString() //JSON.stringify(params)
	} else {
		config.params = params
	}
	return request(config)
}
export { fetch, request }
