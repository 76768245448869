import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		tabs: {},
		childName: '',
		columnlist: []
	},
	mutations: {
		setTabs(state, data) {
			state.tabs = data
		},
		setChildName(state, data) {
			state.childName = data
		},
		columnList(state, data) {
			state.columnlist = data
		}
	}
})

export default store
