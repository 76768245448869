<template>
	<div id="app">
		<Header :routerList="routerList"></Header>
		<div class="conTent">
			<router-view />
		</div>
		<Footer></Footer>
		<div v-show="showTop" class="Back-top el-icon-top" @click="toTop"></div>
	</div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
	data() {
		return {
			routerList: [
				{
					text: '头部'
				}
			],
			currentTop: '',
			showTop: false
		}
	},
	components: {
		Header,
		Footer
	},
	watch: {
		$route: function (to, from) {
			// document.body.scrollTop = 100
			// document.documentElement.scrollTop = 100
		}
	},
	mounted() {
		console.log(process.env.VUE_APP_SERVER_URL, '---------')
		console.log(process.env.NODE_ENV, '---------')
		this.$router.replace('/')
		window.addEventListener('scroll', this.scrollToTop)
	},
	methods: {
		// 监听页面滚动
		scrollToTop() {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			this.showTopTimer && clearTimeout(this.showTopTimer)
			this.showTopTimer = setTimeout(() => {
				this.handleScrollEnd()
			}, 200)
			this.currentTop = scrollTop
			if (scrollTop > 600) {
				this.showTop = true
			} else {
				this.showTop = false
			}
		},
		// 停止滚动时触发
		handleScrollEnd() {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if (scrollTop === this.currentTop && scrollTop > document.documentElement.clientHeight) {
				//this.showTop = true
				clearTimeout(this.showTopTimer)
			}
		},
		// 返回顶部动画效果
		toTop() {
			var u = navigator.userAgent
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			// 实现滚动效果
			let speed = scrollTop / 10 // 每次滚动多少 （步长值）
			const timeTop = setInterval(() => {
				if (document.documentElement.scrollTop !== 0) {
					document.documentElement.scrollTop -= speed // 不在顶部 每次滚动到的位置
				} else {
					clearInterval(timeTop) // 回到顶部清除定时器
				}

				// if (isIOS) {
				// 	if (document.documentElement.scrollTop !== 0) {
				// 		document.documentElement.scrollTop -= speed // 不在顶部 每次滚动到的位置
				// 	} else {
				// 		clearInterval(timeTop) // 回到顶部清除定时器
				// 	}
				// }
				// if (isAndroid) {
				// 	if (document.body.scrollTop != 0) {
				// 		document.body.scrollTop -= speed
				// 	} else {
				// 		clearInterval(timeTop)
				// 	}
				// }
			}, 20)
		}
	},
	// 退出页面时取消监听
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollToTop)
	}
}
</script>
<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow: auto;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
.conTent {
	/* margin-top: 144px; */
}
.Back-top {
	position: fixed;
	width: 40px;
	height: 40px;
	right: 30px;
	bottom: 200px;
	border-radius: 50%;
	z-index: 1000;
	background: #ccc;

	color: #333;
	text-align: center;
	line-height: 40px !important;
	font-size: 22px;
	cursor: pointer;
}
.Back-top:hover {
	background: #ccc;
	color: #fff;
}
</style>
