import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/indexPage/index.vue')
	},

	{
		path: '/learn',
		name: 'learn',
		component: () => import('../views/learn/index.vue')
	},
	{
		path: '/aboutus',
		name: 'aboutus',
		component: () => import('../views/aboutus/index.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news/index.vue')
	},
	{
		path: '/newdetaile',
		name: 'newdetaile',
		component: () => import('../views/newsDetailed/index.vue')
	},
	{
		path: '/searchpage',
		name: 'searchpage',
		component: () => import('../views/searchPage/index.vue')
	}
]

const router = new VueRouter({
	routes
})

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})

export default router
