<template>
	<div class="footer">
		<div class="footer-box">
			<div class="footer-item-box">
				<div class="footer-tips">网站导航</div>
				<div class="Navigation">
					<div
						v-for="(item, i) in menuList"
						:key="i"
						@click="footerLink(item, i)"
						:class="item.columnId == tabItem.columnId ? 'footer-active' : ''"
					>
						{{ item.columnName }}
					</div>
				</div>
			</div>
			<div class="footer-item-box">
				<div class="footer-tips">联系我们</div>
				<div class="contact">
					<div class="contact-item">
						<div class="contact-item-icon"></div>
						<div class="contact-item-text">mail.hnco.sdeu.cn</div>
					</div>
					<div class="contact-item">
						<div class="contact-item-map"></div>
						<div class="contact-item-text">
							郑州大学第一附属医院东院区门诊楼十楼1102室
						</div>
					</div>
					<!-- <div class="follow-int">
						<div></div>
						<div>扫一扫关注微信公众号</div>
					</div> -->
				</div>
			</div>
			<div class="footer-item-box">
				<img class="footer-logo" src="../static/images/footer-logo.png" alt="" />
				<div class="footer-ri-text">
					<p>决策支撑</p>
					<!-- <p>文件下载</p> -->
					<p>法律声明</p>
				</div>
				<div class="note-text">
					<p>版权所有：河南临床肿瘤学会</p>
					<p>豫ICP备2022024466号</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {},
	data() {
		return {
			menuList: this.$store.state.columnlist,
			tabItem: ''
		}
	},
	components: {},
	computed: {},
	watch: {
		'$store.state.columnlist': {
			handler(newVal) {
				this.menuList = newVal
			}
		},
		'$store.state.tabs': {
			handler(newVal) {
				this.tabItem = newVal
			}
		}
	},
	mounted() {},

	methods: {
		footerLink(item, i) {
			this.tabItem = item
			this.$store.commit('setTabs', item)
			switch (item.columnName) {
				case '首页':
					this.$router.replace('/')
					break
				case '关于学会':
					this.$router.replace('/learn')
					break
				default:
					this.$router.replace('/news')
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.footer {
	width: 100%;

	background: #313131;

	.footer-box {
		width: 1330px;
		margin: 0 auto;
		padding: 54px 100px;
		display: flex;
		flex-direction: row;
	}
	.footer-item-box {
		flex: 3;
		.footer-tips {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 42px;
			padding-bottom: 12px;
			position: relative;
			text-align: left;
		}

		.footer-tips::before {
			position: absolute;
			content: '';
			bottom: 0px;
			width: 30px;
			border: 2px solid #288183;
		}
		.Navigation {
			width: 100%;
			margin-top: 50px;
			overflow: hidden;
			div {
				width: 50%;
				float: left;
				text-align: left;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #ffffff;
				line-height: 42px;
				cursor: pointer;
			}
			.footer-active {
				color: #288183;
			}
		}
		.contact {
			width: 100%;
			margin-top: 50px;
			.contact-item {
				display: flex;
				flex-direction: row;
				margin-bottom: 10px;
				.contact-item-icon {
					width: 24px;
					height: 23px;
					background: url(../static/images/footer-icon1.png) no-repeat center;
					// background-size: 100% 100%;
					margin-top: 8px;
				}
				.contact-item-map {
					width: 30px;
					height: 26px;
					background: url(../static/images/footer-icon2.png) no-repeat center;
					// background-size: 100% 100%;
					margin: 7px 2px 0px 0px;
					margin-left: 2px;
				}
				.contact-item-text {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 24px;
					padding: 7px 0px 0px 10px;
					text-align: left;
				}
			}
			.follow-int {
				display: flex;
				flex-direction: row;
				margin-top: 44px;
				div:nth-child(1) {
					width: 109px;
					height: 109px;
					background: url(../static/images/qecode.png) no-repeat center;
					background-size: 100% 100%;
				}
				div:nth-child(2) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 20px;
					width: 72px;
					padding-left: 10px;
					margin-top: 72px;
				}
			}
		}
		.footer-logo {
			width: 137px;
			height: 60px;
			float: right;
		}
		.footer-ri-text {
			float: right;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 190px;
			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #ffffff;
				line-height: 14px;
				text-align: right;
				padding: 0px 8px;
				border-right: 1px solid #fff;
			}
			p:last-child {
				border: none;
				padding-right: 0px;
			}
		}
		.note-text {
			float: right;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 24px;
			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #ffffff;
				line-height: 14px;
				text-align: right;
			}
			p:nth-child(1) {
				padding-right: 24px;
			}
		}
	}
}
</style>
