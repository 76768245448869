import { fetch } from '../utils/merge.js'
const host = process.env.NODE_ENV == 'development' ? '/zdywz' : process.env.VUE_APP_SERVER_URL
console.log(host)
export function loginH5(options) {
	//head 菜单列表
	const url = host + '/loginH5'
	return fetch(url, options, 'post', 'json')
}

export function listColumn(options) {
	//head 菜单列表
	const url = host + '/api/listColumn'
	return fetch(url, options, 'post', 'json')
}
export function listInfoByColumnName(options) {
	const url = host + '/api/listInfoByColumnName'
	return fetch(url, options, 'post', 'json')
}

export function listInfoByColumnId(options) {
	const url = host + '/api/listInfoByColumnId'
	return fetch(url, options, 'post', 'json')
}

export function listInfoByInfoName(options) {
	const url = host + '/api/listInfoByInfoName'
	return fetch(url, options, 'post', 'json')
}
export function listDept(options) {
	const url = host + '/api/listDept'
	return fetch(url, options, 'post', 'json')
}
export function pageListInfoByColumnName(options) {
	const url = host + '/api/pageListInfoByColumnName'
	return fetch(url, options, 'post', 'json')
}

export function pageListInfoByColumnId(options) {
	const url = host + '/api/pageListInfoByColumnId'
	return fetch(url, options, 'post', 'json')
}

export function listInfoByInfoId(options) {
	const url = host + '/api/listInfoByInfoId'
	return fetch(url, options, 'post', 'json')
}
