<template>
	<div class="header">
		<img class="logo" src="../static/images/logo.png" alt="" />
		<div class="head-ri">
			<div class="head-search">
				<div class="search-box">
					<el-input
						class="searchInput"
						@blur="searchblur"
						@focus="searchfocus"
						v-model="searchVla"
						@input="search"
						placeholder="请输入标题"
					></el-input>
					<!-- <transition name="el-zoom-in-top">
						<ul v-if="searchState && searchList.length" class="searchlist-box">
							<li v-for="(item, i) in searchList" :key="i" @click="searchItem(item, i)">{{ item.noticeTitle }}</li>
						</ul>
					</transition> -->
					<i class="el-icon-search search-icon" @click="searchBtn"></i>
				</div>
				<el-button class="Sign" round @click="SignBtn">登 录</el-button>
			</div>
			<div class="head-tab">
				<div
					class="tab-item"
					v-for="(item, i) in ColumnList"
					:key="i"
					@click="tabBtn(item, i)"
					:class="headTablen == i ? 'tab-item-before' : ''"
				>
					{{ item.columnName }}
				</div>
			</div>
		</div>

		<el-dialog
			title=""
			:visible.sync="SigndialogVisible"
			close-on-click-modal
			width="742px"
			:before-close="handleClose"
		>
			<img class="Sig-img" src="../static/images/sin.jpg" alt="" />
			<div class="input-text">
				<el-input v-model="username" placeholder="请输入账号"></el-input>
			</div>
			<div class="input-text">
				<el-input v-model="password" show-password placeholder="请输入密码"></el-input>
			</div>
			<div class="submit">
				<el-button type="primary" @click="Signin">登录</el-button>
			</div>
			<!-- <span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span> -->
		</el-dialog>
	</div>
</template>
<script>
import { listColumn, listInfoByInfoName, loginH5 } from '@/api/api.js'
import { debounceInput } from '@/utils/debounce.js'
export default {
	data() {
		return {
			searchVla: '',
			ColumnList: [],
			headTablen: 0,
			searchState: true,
			searchList: [],
			SigndialogVisible: false,
			username: '',
			password: ''
		}
	},
	props: {},
	components: {},
	computed: {
		tabItem() {
			return this.$store.state.tabs
		}
	},
	mounted() {
		listColumn({ parentId: 100 })
			.then(res => {
				if (res.length) {
					for (let i = 0; i < res.length; i++) {
						if (res[i].columnId != 100) {
							this.ColumnList.push(res[i])
						}
					}
					this.$store.commit('columnList', res)
				}
			})
			.catch(error => {
				this.$message.error('获取数据失败！')
			})
	},
	methods: {
		searchBtn() {
			//点击搜索图标
			if (this.searchVla) {
				this.$router.replace({
					path: '/searchpage',
					query: {
						name: this.searchVla
					}
				})
			} else {
				this.$message.error('请输入搜索内容！')
			}
		},
		Signin() {
			if (!this.username) {
				this.$message.error('请输入账号！')
				return
			}
			if (!this.password) {
				this.$message.error('请输入密码！')
				return
			}
			let params = {
				userName: this.username,
				password: this.password
			}
			loginH5(params)
				.then(res => {
					if (res.code == 0) {
						window.open('https://www.hsco.org.cn/gw/login')
						this.SigndialogVisible = false
					} else {
						this.$message.error(res.msg)
					}
				})
				.catch(e => {})
		},
		SignBtn() {
			this.SigndialogVisible = true
		},
		handleClose() {
			this.SigndialogVisible = false
		},
		searchblur() {
			// setTimeout(() => {
			// 	//	this.searchState = false
			// }, 200)
		},
		searchItem(item, i) {
			console.log(item)
			let menu = this.$store.state.columnlist
			for (let i = 0; i < menu.length; i++) {
				if (menu[i].columnName == item.sysColumn.columnName) {
					this.$store.commit('setTabs', menu[i])
				}
			}
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		searchfocus() {
			//this.searchState = true
		},
		search() {
			// if (this.searchVla != '') {
			// 	debounceInput(() => {
			// 		let params = {
			// 			infoName: this.searchVla
			// 		}
			// 		listInfoByInfoName(params)
			// 			.then(res => {
			// 				this.searchList = res
			// 			})
			// 			.catch(error => {
			// 				this.$message.error('搜索失败！')
			// 			})
			// 	}, 1000)
			// } else {
			// 	this.searchList = []
			// 	this.searchState = false
			// }
		},

		tabBtn(item, i) {
			this.headTablen = i
			this.$store.commit('setTabs', item)
			switch (item.columnName) {
				case '首页':
					this.$router.replace('/')
					break
				case '关于学会':
					this.$router.replace('/learn')
					break
				default:
					this.$router.replace('/news')
			}
		}
	},
	watch: {
		tabItem: {
			handler(newVla, oldVal) {
				newVla
				for (let i = 0; i < this.ColumnList.length; i++) {
					if (newVla.columnId == this.ColumnList[i].columnId) {
						this.headTablen = i
					}
				}
			},
			deep: true,
			immediate: false
		}
	}
}
</script>
<style scoped lang="scss">
.header {
	width: 1330px;
	height: 163px;
	margin: 0 auto;
	background: #fff;
	// overflow: hidden;

	.logo {
		width: 419px;
		height: auto;
		margin: 57px 0px 44px;
		float: left;
	}
	.head-ri {
		float: right;
		padding: 40px 0px 31px;
		width: 911px;

		.head-search {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			.search-box {
				position: relative;
				.search-icon {
					position: absolute;
					left: 257px;
					top: 10px;
					color: #288183;
					font-size: 18px;
					cursor: pointer;
				}
				.searchlist-box {
					width: 100%;
					background: #fff;
					max-height: 500px;
					margin: 5px 0;
					border-radius: 4px;
					border: 1px solid #e4e7ed;
					box-sizing: border-box;
					background-color: #fff;
					position: absolute;
					left: 0px;
					z-index: 100;
					overflow: auto;
					li {
						margin: 0;
						line-height: 34px;
						cursor: pointer;
						color: #606266;
						font-size: 14px;
						list-style: none;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding: 0px 20px;
					}
					li:hover {
						background-color: #f5f7fa;
					}
				}
			}
			.Sign {
				height: 40px;
				border-color: #288183;
				color: #288183;
				margin-left: 10px;
			}
		}
		.head-tab {
			display: flex;
			// justify-content: flex-end;
			padding-top: 21px;
			.tab-item {
				margin-left: 20px;
				padding: 0px 10px;
				position: relative;
				text-align: center;
				color: #222;
				font-size: 14px;
				padding-bottom: 10px;
				cursor: pointer;
			}
			.tab-item-before::before {
				content: '';
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				height: 6px;
				background: #288183;
			}
		}
	}
}
.Sig-img {
	width: 432px;
	height: 48px;
	display: block;
	margin: 50px auto;
}
.input-text {
	width: 432px;
	margin: 0 auto;
	margin-top: 20px;
	border-bottom: 1px solid #c9c9c9;
	::v-deep .el-input__inner {
		border: none;
	}
}
.submit {
	width: 432px;
	margin: 0 auto;
	margin-top: 45px;
	button {
		width: 100%;
		padding: 16px 20px;
		background: #1d7679;
		color: #fff;
	}
	::v-deep .el-button:hover {
		color: #fff !important;
	}
}
::v-deep .el-dialog__body {
	padding: 30px 20px 90px 20px !important;
}

::v-deep .el-button:hover {
	color: #288183;
}
.searchInput {
	width: 287px;
	input {
	}
}

::v-deep .searchInput input {
	border-radius: 20px;
	height: 40px;
	line-height: 40px;
	border-color: #288183;
}
::v-deep .is-focus {
	border-color: #288183;
}
</style>
