import Es6Promise from 'es6-promise'
import 'babel-polyfill'
// require('es6-promise').polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './icon/iconfont.css'
import '@/common/css/all.css'
import echarts from 'echarts'
Es6Promise.polyfill()

Vue.prototype.$echarts = echarts
import NProgress from 'nprogress' //页面加载顶部进度条
import 'nprogress/nprogress.css'
NProgress.configure({
	easing: 'ease', // 动画方式
	speed: 500, // 递增进度条的速度
	showSpinner: false, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach((to, from, next) => {
	// 每次切换页面时，调用进度条
	NProgress.start()
	next()
})
//跳转结束了
router.afterEach(() => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done()
})
import Router from 'vue-router'
const originalPush = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}
	return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
